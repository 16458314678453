.sub {
  .subContent__section {
    padding: 5rem 0;
    &.pt-0 {
      padding-top: 0;
    }
    @screen lg {
      padding: 3.5em 0;
    }
  }

  p {
    font-size: 18px;
    line-height: 30px;
    color: #232323;
    @screen lg {
      font-size: 16px;
      line-height: 24px;
    }
    @screen md {
      line-height: 20px;
      font-size: 14px;
    }
  }
}
.subvisual {
  .container {
    background-size: cover;
    background-position: center center;
    max-width: 100%;
    padding: 0 30px;
    height: 20rem;
    @screen lg {
      padding: 0 15px;
      height: 15rem;
    }
    @screen md {
      padding: 0 0px;
      height: 10rem;
    }
  }
}

// 회사소개
.intro {
  .intro__title {
    font-size: 32px;
    margin-bottom: 1em;
    color: #1a1a7b;
    word-break: keep-all;
    span {
      font-size: 24px;
      display: block;
      color: #232323;
      margin-top: 5px;
    }
    @screen lg {
      font-size: 28px;
      span {
        font-size: 24px;
      }
    }
    @screen md {
      font-size: 24px;
      span {
        font-size: 18px;
      }
    }
  }
}

.history {
  .history__bottom-img {
    position: relative;
    top: -18px;
  }
  .col {
    border-left: 1px solid #e7e7ef;
    display: flex;
    align-items: flex-end;
    padding-left: 1.5em;
    padding-right: 1em;
    flex: 1;
    padding-bottom: 1em;
    position: relative;
    @screen md {
      display: block;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -4px;
      bottom: -4px;
      width: 7px;
      height: 7px;
      background: #0d49f7;
      border-radius: 50%;
      z-index: 1;
      @screen md {
        top: 15px;
        bottom: auto;
      }
    }
  }
  .history__header {
    p {
      margin-top: 10px;
      font-size: 16px;
      color: #888895;
      font-weight: 500;
      line-height: 1.25em;
    }
  }

  .history__row-title {
    color: #1810b7;
  }
  .history__year {
    font-size: 2.5rem;
    @screen lg {
      font-size: 2rem;
    }
    @screen md {
      font-size: 1.875rem;
    }
    span {
      font-size: 1.125rem;
      @screen md {
        font-size: 1rem;
      }
    }
  }
}

// 콘텍트
.contact__map {
  margin-top: 2em;
  width: 100%;
  height: 400px;
}

.partner {
  .partner__table {
    border-top: 2px solid #1e228e;
    margin-top: 3em;
    h3 {
      border-bottom: 1px solid #d7d7db;
      border-right: 1px solid #d7d7db;
      text-align: center;
      &:last-child {
        border-right: 0;
      }
      line-height: 50px;
    }
    .col {
      border-bottom: 1px solid #d7d7db;
      border-right: 1px solid #d7d7db;
      text-align: center;
      &:last-child {
        border-right: 0;
      }
    }
  }
}

.document__download {
  width: 380px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  font-weight: 600;
  @apply border-hh-deep-blue;
  @apply text-hh-deep-blue;

  @screen md {
    width: 100%;
    height: 50px;
  }
  span {
    padding-right: 2em;
    background: url("../images/icon-download.png") no-repeat right center;
    background-size: auto 1em;
  }
}
