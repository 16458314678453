.table {
  border-top: 2px solid #1e228e;
  table {
    tr.bg-th {
      td {
        background: #fafbfd;
        border-bottom: 2px solid #9d9fcd;
      }
    }
    th,
    td {
      height: 65px;
      font-weight: 400;
      font-size: 16px;
      border-bottom: 1px solid #d7d7db;
      border-right: 1px solid #d7d7db;
      text-align: center;
      &:last-child {
        border-right: 0;
      }
      @screen md {
        font-size: 14px;
      }
      @screen sm {
        height: 50px;
        font-size: 12px;
      }
    }
    th {
      background: #fafbfd;
    }
  }
}
