@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");
@tailwind base;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  font-weight: 500;
}
html {
  font-family: "Noto Sans KR", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.25; /* 2 */
  font-size: 14px;
  letter-spacing: -0.06em;
}

img {
  vertical-align: top;
  max-width: 100%;
}
table {
  width: 100%;
  table-layout: fixed;
}
@tailwind components;
@tailwind utilities;

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

select {
  appearance: none;
}
select::-ms-expand {
  display: none;
}
