.select {
  select {
    height: 50px;
    border: 1px solid #6c6c6c;
    padding: 0 1em;
    font-size: 14px;
    border-radius: 0;
    background: url("../images/select-arrow.png") no-repeat;
    background-position: calc(100% - 1em) center;
    background-size: auto 10px;
    min-width: 250px;
  }
}
