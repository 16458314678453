.mainpage {
  .section1 {
    .container {
      max-width: 100%;
      padding: 0 30px;
      @screen lg {
        padding: 0 15px;
      }
      @screen md {
        padding: 0 0px;
      }
    }
    .sectionbox {
      background: url("/assets/images/main-bg.png") no-repeat center center;
      background-attachment: fixed;
      background-size: cover;
      height: 840px;
      text-align: center;
      word-break: keep-all;
      @screen lg {
        height: 640px;
      }
      @screen md {
        height: 440px;
      }
      h1 {
        overflow: hidden;
        height: 1em;
        padding-top: 1em;
        opacity: 0;
        font-size: 60px;
        transition: all 1s;
        @screen lg {
          font-size: 40px;
        }
        @screen md {
          font-size: 2.25rem;
        }
      }
      p {
        overflow: hidden;
        height: 1em;
        opacity: 0;
        padding-top: 1em;
        font-size: 2.25rem;
        margin-top: 0.5em;
        transition: all 1.5s;
        @screen lg {
          font-size: 1.875rem;
        }
        @screen md {
          font-size: 1.5rem;
        }
      }
      &.js-active {
        h1 {
          height: auto;
          padding-top: 0;
          opacity: 1;
        }
        p {
          height: auto;
          padding-top: 0;
          opacity: 1;
        }
      }
    }
  }

  .section2 {
    padding: 100px 0;
    @screen lg {
      padding: 50px 0;
    }
    .container {
      padding: 0;
    }
    .layout__item {
      padding: 10px 30px;
      width: 33.333%;
      transition: all 0.3s;
      position: relative;
      z-index: 100;
      &:hover {
        transform: scale(1.05);
      }
      @screen lg {
        width: 50%;
        padding: 10px 20px;
      }
      @screen md {
        padding: 10px;
      }

      h2 {
        font-size: 2rem;
        font-weight: 700;
        word-break: keep-all;
        strong {
          color: #000098;
          font-weight: 600;
        }
        @screen md {
          font-size: 1.5rem;
        }
      }
    }
    a {
      display: flex;
      color: #fff;
      line-height: 1.5em;
      background-size: cover;
      background-position: center;
      height: 465px;
      @screen md {
        height: 350px;
      }
      strong {
        display: block;
        margin-bottom: 1em;
        line-height: 1em;
      }
      span {
        color: #000098;
        display: block;
        font-weight: 600;
        padding-left: 1em;
        border-left: 2px solid #000098;
        line-height: 1em;
        margin-bottom: 1em;
      }
    }
  }
}
