.footer {
  margin-top: auto;
  background: #fafafa;
  padding: 3.5em 0;
  @screen lg {
    padding: 2.5em 0;
  }
  .footer__content-bottom {
    margin-top: 1em;

    p {
      line-height: 1.5em;
      color: #6d6d6d;
      font-size: 16px;
      @screen md {
        font-size: 14px;
      }
    }
  }
  .footer__content-top {
    a {
      line-height: 35px;
      padding: 0 1.5em;
      position: relative;
      font-size: 16px;
      @screen md {
        font-size: 14px;
      }
      &:after {
        content: "";
        width: 1px;
        height: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #333;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child:after {
        display: none;
      }
    }
  }
}
