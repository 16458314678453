$headerHeight: 100px;
$headerHoverHeight: $headerHeight + 237px;

.header {
  overflow: hidden;
  height: $headerHeight;
  transition: all 0.5s;
  z-index: 1000;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #efefef;
    position: absolute;
    left: 0;
    top: $headerHeight;
  }

  @screen lg {
    height: auto;
  }
  // 1200 이상 pc 일때만 호버
  @media screen and (min-width: 1200px) {
    &:hover {
      height: $headerHoverHeight;
      background: #fff;
    }
  }

  .container {
    height: $headerHeight;
    @screen lg {
      height: 65px;

      .header__logo img {
        height: 35px;
      }
    }
  }

  .header__gnb {
    height: $headerHeight;

    .header__gnb-link {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .header__depth {
    top: $headerHeight;
    padding: 1em 0;
    a {
      font-size: 16px;
      line-height: 2.5em;
      &:hover {
        color: #000095;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .mobile-header {
    position: fixed;
    left: 0;
    top: 65px;
    width: 100%;
    z-index: 4000;
    height: calc(100vh - 65px);
    background: #fff;
    display: none;
    z-index: 8000;
    border-top: 1px solid #ccc;
  }
  .mobile-header .bg-active {
    background: #012a73;
    color: #fff !important;
  }
  .mobile-header .header {
    position: relative;
  }
  .mobile-header .header__logo {
    margin-right: 10px;
  }
  .mobile-header .header__logo a {
    display: none;
  }
  .mobile-header .header__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
  }
  .mobile-header form {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .mobile-header__auth {
  }
  .mobile-header__auth .wrap {
    height: 90px;
    background: #012a73;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mobile-header__auth p {
    color: #fff;
    line-height: 20px;
    font-size: 14px;
  }
  .mobile-header__auth .thumb {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  .mobile-header__auth .thumb img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .mobile-header__auth .text {
  }
  .mobile-header__auth .text h2 {
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .mobile-header__auth .btnbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .mobile-header__auth .btnbox .btn {
    height: 25px;
    width: 80px;
    background: none;
    border: 1px solid #fff;
    color: #fff;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
  }
  .mobile-header__auth .btnbox .btn:first-child {
    margin-right: 5px;
    background: #fff;
    color: #012a73;
  }
  .mobile-header__nav {
    display: block;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .mobile-header__nav .depth1 {
  }
  .mobile-header__nav .depth1 a {
    display: block;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    font-size: 14px;
    color: #666666;
  }
  .mobile-header__nav .depth1 .depth1__box {
    border-bottom: 1px solid #cccccc;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  /*.mobile-header__nav .depth1 .depth1__box:after{content:'';display: block;clear:both;}*/
  .mobile-header__nav .depth1 .depth1__box a {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .mobile-header__nav .depth1 .depth1__box .toggle-depth3,
  .mobile-header__nav .depth1 .depth1__box .toggle-depth2.has-depth {
    height: 40px;
    width: 40px;
    background: url("../images/icon-plus.png") no-repeat center center;
  }
  .mobile-header__nav .depth1 .depth1__box .toggle-depth3.active,
  .mobile-header__nav .depth1 .depth1__box .toggle-depth2.has-depth.active {
    background: url("../images/icon-minus.png") no-repeat center center;
  }
  .mobile-header__nav .depth1 > a.active + .depth2 {
    display: block;
  }
  .mobile-header__nav .depth3 {
    border-bottom: 1px solid #ccc;
    display: none;
    background: #f8f8f8;
    display: none !important;
  }
  .mobile-header__nav .depth2 {
    background: #f4f4f4;
    display: none;
  }
  .mobile-header__nav .depth2:after {
    content: "";
    display: block;
    clear: both;
  }
  .mobile-header__nav .depth2 .depth2__box {
    float: left;
    width: 33.333%;
    border-right: 1px solid #ccc;
    position: relative;
  }
  .mobile-header__nav .depth2 .depth2__box:nth-child(3n) {
    border-right: 0;
  }
  .mobile-header__nav .depth2 .depth2__box:nth-child(n + 4) {
    border-top: none;
  }
  .mobile-header__nav .depth2 .depth2__box:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid #ccc;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    border-width: 1px 1px 0 0;
  }
  .mobile-header__nav .depth2 a {
    font-size: 13px;
  }
  .mobile-header__nav .depth2 a:last-child {
    border-bottom: 0;
  }
  .mobile-header__nav .depth2 .toggle-depth3 {
    display: none !important;
  }
}
